<template>
	<modal
		:classes="['filters-modal']"
		name="filters-modal"
		height="auto"
		width="325px"
		:clickToClose="clickToClose"
		:scrollable="scrollable"
		@before-open="openModal"
	>
		<div class="content-wrapper">
			<div class="modal-header">
				<img
					class="close-modal"
					:src="require(`../../assets/img/icons/x.png`)"
					@click="closeModal"
				>
			</div>
			<div class="form-wrapper">
				<div class="input-wrapper">
					<div class="input-label">
						Valor do imóvel
					</div>
					<input
						type="text"
						inputmode="numeric"
						class="input"
						placeholder="00,00"
						v-model="$v.buildingCost.$model"
						v-money="realFormat"
					/>
					<template v-if="isDirty">
						<span
							v-show="$v.buildingCost.$invalid"
							class="error"
						>
							Informe um valor válido.
						</span>
					</template>
				</div>
				<div class="input-wrapper">
					<div class="input-label">
						Valor de entrada + FGTS
					</div>
					<input
						type="text"
						inputmode="numeric"
						class="input"
						placeholder="00,00"
						v-model="$v.buildingEntryCost.$model"
						v-money="realFormat"
					/>
					<template v-if="isDirty">
						<span
							v-show="$v.buildingEntryCost.$invalid"
							class="error"
						>
							Informe um valor de entrada válido.
						</span>
					</template>
					<div class="input-description">
						* Mínimo de 10% do valor do imóvel.
					</div>
				</div>
				<div class="input-wrapper">
					<div class="input-label">
						Data de nascimento
					</div>
					<input
						type="text"
						inputmode="numeric"
						class="input"
						v-mask="'##/##/####'"
						v-model="birthDate"
						placeholder="dd/mm/aaaa"
					/>
					<template v-if="isDirty">
						<span
							v-show="$v.birthDate.$invalid"
							class="error"
						>
							Informe uma data válida.
						</span>
					</template>
				</div>
				<div class="input-wrapper">
					<div class="input-label">
						Prazo (em meses)
					</div>
					<input
						type="text"
						inputmode="numeric"
						class="input"
						v-model="months"
						placeholder="420"
					/>
					<template v-if="isDirty">
						<span
							v-show="$v.months.$invalid"
							class="error"
						>
							Informe prazo válido.
						</span>
					</template>
					<div class="input-description">
						* Máximo de 420 meses.
					</div>
				</div>
			</div>
			<button
				class="button"
				@click="submit"
			>
				Simular novos valores
			</button>
		</div>
	</modal>
</template>

<script>
import { required, minLength, integer } from 'vuelidate/lib/validators';
import real from '@/assets/js/v-money-real';
import { dateValidation, notZeroValidation, buildingCostEntryValidation, monthsValidation } from '../../assets/js/customValidations';

export default {
	data () {
		return {
			buildingCost: '',
			buildingEntryCost: '',
			birthDate: '',
			months: '',
			// validation
			isDirty: false,
			realFormat: '',
			// modal
			scrollable: false,
			clickToClose: false,
			scrollableBreakpoint: 1000
		}
	},
	validations: {
		buildingCost: { notZeroValidation },
		buildingEntryCost: { notZeroValidation, buildingCostEntryValidation },
		birthDate: { required, minLength: minLength(10), dateValidation },
		months: { required, integer, monthsValidation }
	},
	mounted () {
		this.realFormat = real;
	},
	methods: {
		submit () {
			if (this.validate()) {
				this.$store.commit('setFilters', {
					buildingCost: this.buildingCost,
					buildingEntryCost: this.buildingEntryCost,
					birthDate: this.birthDate,
					months: this.months
				});

				this.$emit('filter');

				this.closeModal();
			}
		},
		validate () {
			this.isDirty = true;

			this.$v.buildingCost.$touch();
			this.$v.buildingEntryCost.$touch();
			this.$v.birthDate.$touch();
			this.$v.months.$touch();

			if (this.$v.$invalid == false) {
				this.isDirty = false;
				return true;
			} else {
				return false;
			}
		},
		openModal () {
			this.getFilters();

			this.scrollable = window.innerWidth < this.scrollableBreakpoint;
			this.clickToClose = window.innerWidth >= this.scrollableBreakpoint;
		},
		closeModal () {
			this.$modal.hide('filters-modal')
		},
		getFilters () {
			const filters = this.$store.getters.getFilters;
			// console.log(filters);
			this.buildingCost = filters.buildingCost;
			this.buildingEntryCost = filters.buildingEntryCost;
			this.birthDate = filters.birthDate;
			this.months = filters.months;
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/style/forms.scss";

.filters-modal {
	border-radius: 16px;

	@media only screen and (min-width: 400px) and (max-width: 641px) {
		margin: 2rem 0;
	}

	.content-wrapper {
		padding: 0.75rem 0.75rem 2rem 0.75rem;
		display: flex;
		flex-direction: column;
		align-items: center;

		.modal-header {
			display: flex;
			justify-content: flex-end;
			width: 100%;

			.close-modal {
				cursor: pointer;
			}
		}

		.form-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 2rem;

			@media only screen and (min-width: 1000px) {
				margin-bottom: 1rem;
			}

			.input-wrapper {
				margin-bottom: 2rem;
			}
		}

		.button {
			@media only screen and (min-width: 1000px) {
				margin: 0;
			}
		}
	}
}
</style>