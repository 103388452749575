import { maskMoneyBR } from './mask-helper';

const formatSimulation = function (simulation) {
  simulation.buildingCost = maskMoneyBR(simulation.buildingCost);
  simulation.buildingEntryCost = maskMoneyBR(simulation.buildingEntryCost);
  simulation.firstPayment = maskMoneyBR(simulation.firstPayment);
  simulation.lastPayment = maskMoneyBR(simulation.lastPayment);
  simulation.minIncome = maskMoneyBR(simulation.minIncome);
  simulation.financedValue = maskMoneyBR(simulation.financedValue);
  simulation.totalValue = maskMoneyBR(simulation.totalValue);
  simulation.amortization = maskMoneyBR(simulation.amortization);
  simulation.tba = maskMoneyBR(simulation.txAdministrativa);
  simulation.dfi = maskMoneyBR(simulation.dfi);

  for (let i = 0; i < simulation.payments.length; i++) {
    const element = simulation.payments[i];

    element.remainingValue = maskMoneyBR(element.remainingValue);
    element.fee = maskMoneyBR(element.fee);
    element.mip = maskMoneyBR(element.mip);
    element.payment = maskMoneyBR(element.payment);

    simulation.payments[i] = element;
  }

  // const formattedSimulation = {
  //   buildingCost: maskMoneyBR(this.valorImovel),
  //   yearlyFee: (`${(this.txJurosAa * 100)}%`).replace('.', ','),
  //   firstPayment: maskMoneyBR(this.getPrimeiraParcela()),
  //   lastPayment: maskMoneyBR(this.getUltimaParcela()),
  //   months: this.prazo,
  //   minIncome: maskMoneyBR(this.calcularRendaMinima()),
  //   buildingEntryCost: maskMoneyBR(this.valorEntrada),
  //   bankName: this.banco,
  //   bankLogo: this.bancoLogo,
  //   financedValue: maskMoneyBR(this.valorFinanciamento),
  //   totalValue: maskMoneyBR(this.valorFinanciado),
  //   comparativeFinancedValue: this.valorFinanciado,
  //   amortization: maskMoneyBR(this.amortizacao),
  //   dfi: maskMoneyBR(this.dfi),
  //   tba: maskMoneyBR(this.txAdministrativa),
  //   payments: []
  // }

  // for (let i = 1; i <= this.prazo; i++) {
  //   const payment = {
  //     date: DateTime.now().plus({ months: i }).toLocaleString({ month: 'numeric', year: 'numeric' }),
  //     number: i,
  //     remainingValue: maskMoneyBR(this.saldoDevedor[i]),
  //     fee: maskMoneyBR(this.listaJuros[i]),
  //     mip: maskMoneyBR(this.mipCalculada[i]),
  //     payment: maskMoneyBR(this.parcelasFinais[i])
  //   }

  //   formattedSimulation.payments.push(payment);
  // }

  return simulation;
}

export default formatSimulation;