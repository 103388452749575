<template>
  <img src="../../assets/img/logo-concede.svg" alt="Concede Crédito Imobiliário">
</template>

<script>
export default {

}
</script>

<style>

</style>