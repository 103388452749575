<template>
	<div class="simulation">
		<simulation-report ref="simulationReport" />
		<loading-modal />
		<simulation-filters-form @filter="simulate" />
		<simulator-header />
		<div class="filters">
			<div class="filter">
				<div class="label">Valor do imóvel</div>
				<div class="value">{{buildingCost || 'Informe um valor'}}</div>
			</div>
			<div class="filter">
				<div class="label">Valor da entrada</div>
				<div class="value">{{buildingEntryCost || 'Informe um valor'}}</div>
			</div>
			<div class="filter">
				<div class="label">Data de nascimento</div>
				<div class="value">{{birthDate || 'Informe uma data'}}</div>
			</div>
			<div class="filter">
				<div class="label">Número de parcelas</div>
				<div class="value">{{months || 'Informe um prazo'}}</div>
			</div>
		</div>
		<button
			class="button"
			@click="openFiltersModal"
		>
			Simule novos valores
		</button>
		<div class="banks-label">
			<template v-if="simulations.length > 0">
				Compare as taxas dos melhores bancos do Brasil
			</template>
			<template v-else>
				Informe os valores para a simulação
			</template>
		</div>
		<div class="cards-wrapper">
			<template v-for="(item, index) in simulations">
				<simulation-card
					:key="index"
					:simulation="item"
				/>
			</template>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import SimulatorHeader from '../components/General/SimulatorHeader.vue';
import SimulationCard from '../components/Simulation/SimulationCard.vue';
import SimulationFiltersForm from '../components/Simulation/SimulationFiltersForm.vue';
import { maxYears, defaultMonths, defaultEntryValuePercent, fixedSelic, minEntryValuePercent } from '../assets/js/constants';
import { maskDateBR, maskMoneyBR, unmaskDate, unmaskMoney } from '../assets/js/mask-helper';
import { DateTime, Interval } from "luxon";
import formatSimulation from '../assets/js/simulation-helper';
import LoadingModal from '../components/General/LoadingModal.vue';
import SimulationReport from '../components/Simulation/SimulationReport.vue';

export default {
	components: { SimulatorHeader, SimulationCard, SimulationFiltersForm, LoadingModal, SimulationReport },
	data () {
		return {
			// filters
			buildingCost: '',
			buildingEntryCost: '',
			birthDate: '',
			months: '',
			// simulation
			partnership: null,
			lead: null,
			simulations: [],
		}
	},
	mounted () {
		this.setGenerateReportEvent();

		this.getDataFromStore();
		this.autocompleteEmptyFilters();
		this.simulate();
	},
	methods: {
		setGenerateReportEvent () {
			this.$root.$on('generateReport', (simulation) => this.$refs.simulationReport.generateReport(simulation));
		},
		getDataFromStore () {
			this.getFiltersFromStore();
			this.getPartnershipFromStore();
			this.getLeadFromStore();
		},
		getFiltersFromStore () {
			const filters = this.$store.getters.getFilters;

			this.buildingCost = filters.buildingCost;
			this.buildingEntryCost = filters.buildingEntryCost;
			this.birthDate = filters.birthDate;
			this.months = filters.months;
		},
		getPartnershipFromStore () {
			this.partnership = this.$store.getters.getPartnership;
		},
		getLeadFromStore () {
			this.lead = this.$store.getters.getUserData;
		},
		autocompleteEmptyFilters () {
			if (this.buildingCost) {
				if (!this.buildingEntryCost) {
					console.log(this.buildingEntryCost);
					const rawBuildingEntryCost = unmaskMoney(this.buildingCost) * defaultEntryValuePercent;

					this.buildingEntryCost = maskMoneyBR(rawBuildingEntryCost);
				}
			}

			if (this.birthDate) {
				if (!this.months) {
					let inputBirthDate = unmaskDate(this.birthDate);
					const birthDate = DateTime.fromISO(inputBirthDate);
					const today = DateTime.now();
					const age = Interval.fromDateTimes(birthDate, today);

					const ageValue = parseInt((maxYears - age.length('years')) * 12);

					this.months = Math.min(ageValue, defaultMonths);
				}
			}

			this.$store.commit('setFilters', {
				buildingCost: this.buildingCost,
				buildingEntryCost: this.buildingEntryCost,
				birthDate: this.birthDate,
				months: this.months,
			});
		},
		requestSimulation () {
			const buildingCost = unmaskMoney(this.buildingCost)
			const buildingEntryCost = unmaskMoney(this.buildingEntryCost);
			const birthDate = unmaskDate(this.birthDate);

			const endpointURL = `https://api.concede.vc/calculed?`;
			const filtersURL = `value=${buildingCost}&entryValue=${buildingEntryCost}&birthDate=${birthDate}&months=${this.months}`;
			const leadURL = `&leadName=${this.lead.userName}&leadEmail=${this.lead.email}&leadPhone=${this.lead.phone}`;
			let partnershipURL = '';

			if (this.partnership) {
				partnershipURL = `&partnership=${this.partnership.partnerID}&email=${this.partnership.partnerEmail}`;
			}

			const url = encodeURI(endpointURL + filtersURL + leadURL + partnershipURL);

			// console.log(url);
			// setTimeout(this.closeLoadingModal, 500);
			Vue.axios.get(url).then((response) => {
				this.createSimulationCards(response.data);
				this.closeLoadingModal();
			});
		},
		createSimulationCards (simulations) {
			this.simulations = [];
			simulations.forEach(element => {
				// const debug = element;
				// console.log('debug: ', debug);
				this.simulations.push(formatSimulation(element));
			});
		},
		simulate () {
			this.getDataFromStore();

			if (this.hasMinimumData()) {
				this.openLoadingModal();
				this.requestSimulation();
			}
		},
		openFiltersModal () {
			this.$modal.show('filters-modal')
		},
		openLoadingModal () {
			this.$modal.show('loading-modal')
		},
		closeLoadingModal () {
			this.$modal.hide('loading-modal')
		},
		hasMinimumData () {
			return this.buildingCost && this.buildingEntryCost && this.birthDate && this.months;
		},
		debugData () {
			console.log('store: ', this.$store.getters.getFilters);
			console.log('buildingCost: ', this.buildingCost);
			console.log('buildingEntryCost: ', this.buildingEntryCost);
			console.log('birthDate: ', this.birthDate);
			console.log('months: ', this.months);
		}
	}
}
</script>

<style lang="scss">
.simulation {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;

	@media only screen and (min-width: 1000px) {
		padding-bottom: 4rem;
	}

	.filters {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 0.75rem;
		padding: 1rem 1.5rem;
		font-size: 0.875rem;
		width: 100%;
		margin-bottom: 1rem;

		@media only screen and (min-width: 1000px) {
			grid-template-columns: repeat(4, 1fr);
			max-width: 900px;
		}

		.label {
			color: $light-blue;
		}
	}

	.button {
		max-width: 200px;

		@media only screen and (min-width: 1000px) {
			margin-bottom: 2rem;
		}
	}

	.banks-label {
		font-size: 1.25rem;
		padding: 2.5rem 1rem 1rem 1rem;
		color: $deep-blue;

		@media only screen and (min-width: 1000px) {
			margin-bottom: 1rem;
		}
	}

	.cards-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 1rem;
		width: 100%;

		@media only screen and (min-width: 1000px) {
			padding: 0 3rem;
			display: grid;
			gap: 1rem;
			grid-template-columns: repeat(
				auto-fill,
				minmax($bank-card-max-width, 1fr)
			);

			.simulation-card {
				justify-self: center;
			}
		}
	}
}
</style>