<template>
	<div>
		<loading-modal />
		<vue-html2pdf
			:show-layout="false"
			:float-layout="true"
			:enable-download="true"
			:preview-modal="false"
			:paginate-elements-by-height="1400"
			:pdf-quality="2"
			:manual-pagination="true"
			:html-to-pdf-options="htmlToPdfOptions"
			pdf-format="a4"
			pdf-orientation="portrait"
			pdf-content-width="800px"
			@hasDownloaded="hasGenerated"
			ref="html2Pdf"
		>
			<section slot="pdf-content">
				<div
					v-if="simulation"
					class="pdf-wrapper"
				>
					<div class="header">
						<div class="company-logo-wrapper">
							<Logo class="company-logo" />
						</div>
						<div class="bank-logo-wrapper">
							Simulação do banco
							<div
								class="bank-logo"
								:style="`background-image: url(${getBackgroundImage()})`"
							></div>
						</div>
					</div>
					<div class="params">
						<div class="param-panel">
							<div class="panel-label">Valores da Simulação</div>
							<div class="param-row">
								<div class="param-label">Valor do Imóvel</div>
								<div class="param-value">{{simulation.buildingCost}}</div>
							</div>
							<div class="param-row">
								<div class="param-label">Valor da Entrada</div>
								<div class="param-value">{{simulation.buildingEntryCost}}</div>
							</div>
							<div class="param-row">
								<div class="param-label">Valor Financiado</div>
								<div class="param-value">{{simulation.financedValue}}</div>
							</div>
						</div>
						<div class="param-panel">
							<div class="panel-label">Condições</div>
							<div class="param-row">
								<div class="param-label">Taxa Efetiva Total</div>
								<div class="param-value">{{simulation.yearlyFee}}</div>
							</div>
							<div class="param-row">
								<div class="param-label">Número de Parcelas</div>
								<div class="param-value">{{simulation.payments.length}}</div>
							</div>
							<!-- <div class="param-row">
                <div class="param-label">CET</div>
                <div class="param-value">{{simulation.cet}}</div>
              </div> -->
							<div class="param-row">
								<div class="param-label">Renda Mínima</div>
								<div class="param-value">{{simulation.minIncome}}</div>
							</div>
							<div class="param-row">
								<div class="param-label">Somatório</div>
								<div class="param-value">{{simulation.totalValue}}</div>
							</div>
						</div>
					</div>
					<div class="contact">
						<div class="contact-label">
							Gostou do resultado? Clique no botão abaixo e peça sua análise de crédito
						</div>
						<a
							class="button"
							:href="getWhatsappLink()"
							target="_blank"
						>Quero solicitar análise de crédito</a>
					</div>
					<div class="list">
						<div class="list-header">
							<div class="month">Mês</div>
							<div class="number">Nº Parcela</div>
							<div class="remaining-value">Saldo Devedor</div>
							<div class="amortization">Amortização</div>
							<div class="fee">Juros</div>
							<div class="mip">Seguro MIP</div>
							<div class="dfi">Seguro DFI</div>
							<div class="tba">TBA</div>
							<div class="payment">Valor da Parcela</div>
						</div>
						<div
							v-for="(item, index) in simulation.payments"
							:key="`payment-${index}`"
							:class="['list-items', pageBreak(index)]"
						>
							<div class="month">{{item.date}}</div>
							<div class="number">{{item.number}}</div>
							<div class="remaining-value">{{item.remainingValue}}</div>
							<div class="amortization">{{simulation.amortization}}</div>
							<div class="fee">{{item.fee}}</div>
							<div class="mip">{{item.mip}}</div>
							<div class="dfi">{{simulation.dfi}}</div>
							<div class="tba">{{simulation.tba}}</div>
							<div class="payment">{{item.payment}}</div>
						</div>
					</div>
				</div>

			</section>
		</vue-html2pdf>
	</div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import LoadingModal from '../General/LoadingModal.vue'
import Logo from '../General/Logo.vue'

export default {
	components: {
		VueHtml2pdf,
		Logo,
		LoadingModal
	},
	data () {
		return {
			simulation: '',
			htmlToPdfOptions: {
				margin: 12,
				enableLinks: true,
				filename: 'Simulação - Concede Crédito Imobiliário.pdf'
			},
			forcePageBreak: [23, 65, 107, 149, 191, 233, 275, 317, 359, 401]
		}
	},
	methods: {
		hasGenerated () {
			this.$modal.hide('loading-modal');
			// document.body.classList.remove('loading');
		},
		generateReport (simulation) {
			this.simulation = simulation;
			// console.log(this.simulation);
			this.$modal.show('loading-modal');
			// document.body.classList.add('loading');
			setTimeout(() => {
				this.$refs.html2Pdf.generatePdf();
			}, 500)
		},
		getBackgroundImage () {
			return require('../../assets/img/banks/' + this.simulation.bankLogo);
		},
		pageBreak (index) {
			if (this.forcePageBreak.includes(index)) {
				return 'html2pdf__page-break';
			} else {
				return '';
			}
		},
		getWhatsappLink () {
			const phone = "5551984401154";
			const text = `Olá, gostaria de entrar em contato com a Concede Crédito Imobiliário referente a uma simulação feita no banco ${this.simulation.bankName} no valor de ${this.simulation.buildingCost}.`;

			const link = `https://api.whatsapp.com/send?phone=${phone}&text=${text}`;

			return link;
		}
	}
}
</script>

<style lang="scss" scoped>
.form-modal {
	.content {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		font-family: "lato-bold";
		font-size: 1rem;
		padding: 1rem 2rem;
		color: black;

		.loading {
			margin-left: 0.25rem;
			margin-bottom: 2px;
		}
	}
}
.pdf-wrapper {
	width: 700px;

	.header {
		display: flex;
		justify-content: space-between;

		.company-logo {
			height: 50px;
			width: auto;
		}

		.bank-logo-wrapper {
			height: 50px;
			width: auto;
			display: flex;
			flex-direction: column;

			.bank-logo {
				margin-top: 4px;
				height: 50px;
				width: 100%;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}

	.params {
		display: flex;
		justify-content: space-between;
		margin-top: 1rem;

		.param-panel {
			width: 49%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.panel-label {
				font-family: "lato-bold";
				font-size: 1.125rem;
				// color: $blue;
				margin-bottom: 0.5rem;
			}

			.param-row {
				width: 100%;
				display: flex;

				border-left: solid 1px black;
				border-top: solid 1px black;
				border-right: solid 1px black;

				&:last-child {
					border-bottom: solid 1px black;
				}

				.param-label {
					font-family: "lato-bold";
					text-align: left;
					width: 50%;
					background: lightgray;
					padding: 0.25rem;
					border-right: solid 1px black;
				}

				.param-value {
					width: 50%;
					background: white;
					padding: 0.25rem;
				}
			}
		}
	}

	.contact {
		margin-top: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;

		.contact-label {
			font-family: "lato-bold";
			font-size: 1.25rem;
			color: $blue;
			margin-bottom: 1rem;
			text-align: center;
		}
	}

	.list {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 2rem;

		.html2pdf__page-break {
			border-bottom: solid 1px black;
		}

		.list-header {
			font-family: "lato-bold";
			background: lightgray;
		}

		.list-header,
		.list-items {
			display: flex;
			font-size: 0.75rem;
			border-left: solid 1px black;
			border-top: solid 1px black;
			border-right: solid 1px black;

			&:last-child {
				border-bottom: solid 1px black;
			}

			> * {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 4px 0;
				border-right: solid 1px black;

				&:last-child {
					border-right: none;
				}
			}
		}

		.month {
			width: 55px;
		}

		.number {
			width: 65px;
		}

		.remaining-value {
			width: 120px;
		}

		.amortization {
			width: 80px;
		}

		.fee {
			width: 70px;
		}

		.mip {
			width: 70px;
		}

		.dfi {
			width: 70px;
		}

		.tba {
			width: 65px;
		}

		.payment {
			width: 100px;
		}
	}
}
</style>