<template>
	<div class="simulation-card">
		<div class="card-header">
			<div class="bank-wrapper">
				<img
					class="bank-icon"
					:src="require(`../../assets/img/banks/${simulation.bankLogo}`)"
				>
				{{simulation.bankName}}
			</div>
			<button
				class="show-report"
				@click="generateReport"
			>
				Ver Parcelas
			</button>
		</div>
		<div class="data-wrapper">
			<div class="bank-data">
				<span class="label">Valor do Financiamento:</span> {{simulation.financedValue}}
			</div>
			<div class="bank-data highlight">
				<span class="label">Taxa Efetiva a.a.:</span>{{simulation.yearlyFee}}
			</div>
			<div class="bank-data">
				<span class="label">Valor da primeira parcela:</span> {{simulation.firstPayment}}
			</div>
			<div class="bank-data">
				<span class="label">Valor da última parcela:</span> {{simulation.lastPayment}}
			</div>
			<div class="bank-data">
				<span class="label">Prazo:</span> {{simulation.months}} meses
			</div>
			<div class="bank-data">
				<span class="label">Renda mínima:</span> {{simulation.minIncome}}
			</div>
			<div class="bank-data">
				<span class="label">Valor de entrada:</span> {{simulation.buildingEntryCost}}
			</div>
		</div>
		<!-- <button class="button">Solicite seu crédito</button> -->
	</div>
</template>

<script>
export default {
	methods: {
		generateReport () {
			this.$root.$emit('generateReport', this.simulation);
		}
	},
	props: ['simulation'],
	emits: ['generateReport']
}
</script>

<style lang="scss" scoped>
@import "./../../assets/style/forms.scss";

.simulation-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: solid 1px lightgray;
	border-radius: 8px;
	padding: 1rem;
	margin-bottom: 1rem;
	max-width: $bank-card-max-width;
	width: 100%;

	.card-header {
		display: flex;
		justify-content: space-between;
		width: 100%;

		.bank-wrapper {
			display: flex;
			align-items: center;

			.bank-icon {
				margin-right: 0.5rem;
			}
		}

		.show-report {
			background: none;
			border: none;
			color: $light-blue;
			text-decoration: underline;

			&:hover {
				color: $deep-blue;
				cursor: pointer;
			}
		}
	}

	.data-wrapper {
		padding: 0.5rem 0;
		margin-bottom: 1rem;
		width: 100%;

		.bank-data {
			padding: 0.25rem 0;
			color: gray;
			font-size: 0.875rem;

			.label {
				color: black;
				margin-right: 0.25rem;
			}
		}
	}

	.button {
		padding: 0.625rem 1.25rem;
		font-size: 0.875rem;

		@media only screen and (min-width: 1000px) {
			margin: 0;
		}
	}
}
</style>